/* autoprefixer grid: autoplace */

* {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}

*,
:after,
:before {
  box-sizing: border-box;
  flex-shrink: 0;
}

:root {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  cursor: default;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-break: break-word;
  tab-size: 4;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Oxygen, Malgun Gothic, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100%;
}

img,
picture,
video,
canvas,
{
  display: block;
  max-width: 100%;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.swiper-button-prev {
  left: calc(calc(100% - 1100px) / 2) !important;
  background: url('https://res.kurly.com/pc/service/main/1908/ico_prev1_x1.png') no-repeat 50% 50%;
  width: 60px;
  height: 60px;
  transition: opacity 0.5s;

  @media only screen and (max-width: 680px) {
    top: 75% !important;
    left: 0 !important;
    margin-left: 10px;
  }
}

.swiper-button-next {
  right: calc(calc(100% - 1100px) / 2) !important;
  background: url('https://res.kurly.com/pc/service/main/1908/ico_next1_x1.png') no-repeat 50% 50%;
  width: 60px;
  height: 60px;
  transition: opacity 0.5s;

  @media only screen and (max-width: 680px) {
    top: 75% !important;
    right: 0 !important;
    margin-right: 10px;
  }
}

.swiper-button-prev:after {
  content: "" !important;
}

.swiper-button-next:after {
  content: "" !important;
}
